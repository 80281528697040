export const playoff_report_text =
    [
            "Team: JSurls10 -- Made Playoffs: 0.00%[0], Bubble Playoffs: 6.25%[4], Missed Playoffs: 93.75%[60]",
            "    ==== 1 different ways team tied for playoffs ====",
            "    ---- Tied Group 100.00%[4] : Batman57,Daboys2021,JSurls10,Lpalomo",
            "Team: DanielCupit -- Made Playoffs: 100.00%[64], Bubble Playoffs: 0.00%[0], Missed Playoffs: 0.00%[0]",
            "    ==== 0 different ways team tied for playoffs ====",
            "Team: stucabesa -- Made Playoffs: 0.00%[0], Bubble Playoffs: 0.00%[0], Missed Playoffs: 100.00%[64]",
            "    ==== 0 different ways team tied for playoffs ====",
            "Team: Daboys2021 -- Made Playoffs: 12.50%[8], Bubble Playoffs: 50.00%[32], Missed Playoffs: 37.50%[24]",
            "    ==== 5 different ways team tied for playoffs ====",
            "    ---- Tied Group 25.00%[8] : Batman57,Daboys2021",
            "    ---- Tied Group 25.00%[8] : Batman57,Daboys2021,Lpalomo",
            "    ---- Tied Group 25.00%[8] : Daboys2021,Lpalomo",
            "    ---- Tied Group 12.50%[4] : Batman57,Daboys2021,Lpalomo",
            "    ---- Tied Group 12.50%[4] : Batman57,Daboys2021,JSurls10,Lpalomo",
            "Team: DCupit2 -- Made Playoffs: 100.00%[64], Bubble Playoffs: 0.00%[0], Missed Playoffs: 0.00%[0]",
            "    ==== 0 different ways team tied for playoffs ====",
            "Team: jhulme -- Made Playoffs: 100.00%[64], Bubble Playoffs: 0.00%[0], Missed Playoffs: 0.00%[0]",
            "    ==== 0 different ways team tied for playoffs ====",
            "Team: mikestaffel -- Made Playoffs: 100.00%[64], Bubble Playoffs: 0.00%[0], Missed Playoffs: 0.00%[0]",
            "    ==== 0 different ways team tied for playoffs ====",
            "Team: Batman57 -- Made Playoffs: 12.50%[8], Bubble Playoffs: 50.00%[32], Missed Playoffs: 37.50%[24]",
            "    ==== 5 different ways team tied for playoffs ====",
            "    ---- Tied Group 25.00%[8] : Batman57,Daboys2021",
            "    ---- Tied Group 25.00%[8] : Batman57,Daboys2021,Lpalomo",
            "    ---- Tied Group 25.00%[8] : Batman57,Lpalomo",
            "    ---- Tied Group 12.50%[4] : Batman57,Daboys2021,Lpalomo",
            "    ---- Tied Group 12.50%[4] : Batman57,Daboys2021,JSurls10,Lpalomo",
            "Team: jsurls -- Made Playoffs: 100.00%[64], Bubble Playoffs: 0.00%[0], Missed Playoffs: 0.00%[0]",
            "    ==== 0 different ways team tied for playoffs ====",
            "Team: RyanPalomo -- Made Playoffs: 100.00%[64], Bubble Playoffs: 0.00%[0], Missed Playoffs: 0.00%[0]",
            "    ==== 0 different ways team tied for playoffs ====",
            "Team: DavidSenior -- Made Playoffs: 100.00%[64], Bubble Playoffs: 0.00%[0], Missed Playoffs: 0.00%[0]",
            "    ==== 0 different ways team tied for playoffs ====",
            "Team: Lpalomo -- Made Playoffs: 12.50%[8], Bubble Playoffs: 50.00%[32], Missed Playoffs: 37.50%[24]",
            "    ==== 5 different ways team tied for playoffs ====",
            "    ---- Tied Group 25.00%[8] : Batman57,Daboys2021,Lpalomo",
            "    ---- Tied Group 25.00%[8] : Daboys2021,Lpalomo",
            "    ---- Tied Group 25.00%[8] : Batman57,Lpalomo",
            "    ---- Tied Group 12.50%[4] : Batman57,Daboys2021,Lpalomo",
            "    ---- Tied Group 12.50%[4] : Batman57,Daboys2021,JSurls10,Lpalomo",

    ];